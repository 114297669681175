import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import { JobFeature } from "../components/job-components"
import SuccessStory from "../components/success-story"

const SuccessStoryOrbis360 = ({ data }) => {
  return (
    <SuccessStory
      title="Mapping Switzerland while protecting privacy"
      customerName="orbis360"
      customerLocation="Sion, Switzerland"
      customerDescription="Orbis360 offers 3D data collection and engineering services."
      customerIndustries="Engineering Services, Mobile Mapping, Laser Scanning"
      link="https://orbis360.ch/en/"
      logo=""
      style={{
        background: "url(/images/sample-image-orbis360.jpg) no-repeat",
        backgroundPositionY: "center",
        backgroundSize: "cover",
      }}
    >
      <h2 className="my-3">Challenge</h2>
      <p>
        Orbis360 offers versatile data collection and publishing services by utilizing a multitude of mobile mapping and
        laser scanning systems, which they use to collect panoramic and geo-referenced images.
      </p>
      <p>
        <strong>
          Their clients include public administration, public transport, engineering offices, and urban planners who are
          increasingly concerned about data privacy of personal data collected on these images.
        </strong>
      </p>

      <figure className="figure">
        <Img fluid={data.orbisImage.childImageSharp.fluid} className="rounded my-3" />
        <figcaption className="figure-caption">Efficient and accurate capture of the environment while protecting privacy, by orbis360.</figcaption>
      </figure>

      <h2 className="my-3">Solution</h2>
      <p>
        At the early stage of orbis360’s data protection journey, orbis360 used Celantur’s easy-to-use{" "}
        <a href="https://app.celantur.com/" target="_blank" rel="noopener noreferrer">
          cloud service
        </a>{" "}
        to blur personal information contained on images before being delivered to clients. As the amount of data grew,
        orbis360 decided to deploy Celantur Container on-premise.
      </p>

      <h2 className="my-3">Celantur's contribution</h2>
      <JobFeature>Initial offering of Celantur Cloud Service to accommodate smaller projects</JobFeature>
      <JobFeature>Subsequent on-premise deployment of <Link to="/container/">Celantur Container</Link> to meet higher customer demand</JobFeature>
      <JobFeature>Personal data is protected when making data available to orbis360 customers</JobFeature>
    </SuccessStory>
  )
}

export default SuccessStoryOrbis360

export const query = graphql`
  query {
    orbisImage: file(relativePath: { eq: "sample-image-orbis360.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
